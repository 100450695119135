.modal {
  background-color: rgba(48, 48, 48, 0.9);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: fixed;
  height: 100svh;
  width: 100svw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 53;
  animation: openModal 0.3s ease-out;
  overflow: hidden;

  @keyframes openModal {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .wrap {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .actions {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        width: 44px;
        border-radius: var(--border-radius-small);
        z-index: 12;
        transition: 0.15s;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      .sound {
        svg {
          path {
            fill: var(--primary-color);
          }
        }
      }

      .close {
        & > svg {
          & > path {
            stroke: var(--primary-color);
          }
        }
      }
    }

    .content {
      display: flex;
      min-width: 1920px;
      max-width: 1920px;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: visible;
      z-index: 11;
    }
  }
}
